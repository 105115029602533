export default {
  // 学生注册
  nameZh: '中文名 Chinese Name or Full Name',
  nameEN: '英文名',
  lastName: '姓(拼音形式) Surname',
  firstName: '名(拼音形式) First Name',
  gender: '性别 Gender',
  female: '女 Female',
  male: '男 Male',
  title: '称谓 Title',
  birthday: '出生日期 Birthday',
  phoneNumber: '学生联系电话  Student Contact Number',
  phoneRegion: '区号 Area Code ',
  email: '电子邮件 Email',
  emailPassword: '电子邮件密码 Email Password',
  country: '国家或地区 Country or Region of Residence',
  state: '省 Province',
  city: '市 City',
  region: '区 District',
  postcode: '邮政编码 Postal Code',
  address: '联系地址 Contact Address',
  alternativeAddr: '备选联系地址 Alternative Contact Address',
  fillApply: '保存并填写入学申请',
  returnStuList: '返回列表',
  fkAreaRegion: '区域 Region',
  saveTemporal: "信息待完善，暂存",
  moreEmailTips:' (多个邮箱请用(;+空格)隔开)',
  viewStudentsAddApplications:'查看学生并进行加申操作',
  duplicateStudentTips:'检测到，重复学生有{num}个！',
  viewStudentsEditThem:'查看学生并进行编辑操作',
  shareLink:'共享链接',
  notStudentEmail:'(非学生电邮 Not Student Email)',
  submitTips:'学生信息已经提交。若要修改，请通知顾问或在加申的情况下，进行修改。',

  // 学生资料管理
  isLivedSinceBirth: '是否自出生便在这个国家或地区居住？Have you lived in this country or region since birth?',
  fkStudentAppCountryIdLive: '目前所在国家/地区 Current country/region',
  migrationTime: '移居时间 Migration Time',
  stayingRightTime: '取居留权时间 Take the right of abode time',
  isHavePassport: '是否有护照 Do you have a passport',
  fkStudentAppCountryIdPassport: '签发国家或地区 Issuing Country or Region',
  passportIssueDate: '签发日期 Issue Date',
  passportIssueExpried: '有效期至 Valid until',
  passportNumber: '护照号码 Passport Number',
  followUpPhoneCode: '区号 Area Code',
  followUpPhoneNumber1: "跟进联系人电话号码1 Follow up the contact's phone number 1",
  followUpPhoneCode1: '区号1 Area Code 1',
  followUpEmail1: '跟进人电邮1 Follow Up Email 1',
  followUpPhoneNumber2: "跟进联系人电话号码2 Follow up the contact's phone number 2",
  followUpPhoneCode2: '区号2 Area Code 2',
  followUpEmail2: '跟进人电邮2 Follow Up Email 2',
  followUpPhoneNumber3: "跟进联系人电话号码3 Follow up the contact's phone number 3",
  followUpPhoneCode3: '区号3 Area Code 3',
  followUpEmail3: '跟进人电邮3 Follow Up Email 3',
  passportInfo: '护照信息 Passport Information',
  contactPerson: '联系负责人 Contact Person in Charge',
  contactNotice: '(建议提供顾问老师手机号码，以便收取系统推送学生个案进展)',
  atLeastOne: '(至少填写一个) (Fill in at least one)',
  fkStudentAppCountryId: '学生国籍/地区 Student Nationality',
  fkStudentAppAncestryId: '学生血统 Student Lineage',
  fkStudentAppMotherLanguageId: '学生母语 Student\'s native language',
  fkStudentAppCountryIdBirth: '出生国家或地区 Country or Region of Birth',
  cityBirth: '出生城市 Birth City',

  regStartTime: '入档开始时间',
  regEndTime: '入档结束时间',
  export: '导出',
  applicationStatus: '申请当前处理状态',
  failureReason: '失败原因',
  regGmtCreate: '入档时间',
  edit: '编辑申请',
  addApplyCourse: '加申课程',
  personInfo: '个人信息',
  applyFollow: '申请跟进情况',
  allDataRegiest: '完整资料注册',
  baseDataRegiest: '注册基本资料',
  intelligentIdentificationStudentInfo: '智能识别学生资料',
  intelligentIdentificationTips: '通过OCR & NLP识别技术，提取图像、文档中的关键信息，简化资料录入工作',
  uploadPassportNational: '上传其他地区护照图片',
  uploadPassport: '上传中国大陆护照图片',
  uploadBackground: '上传背景资料',
  continuetofill: '继续填写',
  stuName: '姓名（中文）',
  stuNameEn: '姓名（英文）',
  stuGender: '性别',
  stuNation: '民族',
  stuBirth: '出生日期',
  stuAddr: '联系地址',
  stuIdcard: '身份证',
  stuBirthPlace: '出生地',
  stuPassport: '护照号码',
  stuEduExp: '教育经历',
  stuWorkExp: '工作经历',
  notUpload: '未上传',
  stepStatusChangeStartTime: '步骤状态变更开始时间',
  stepStatusChangeEndTime: '步骤状态变更结束时间',
  fkItemChangeStepName: '变更步骤',
  fkItemChangeStepNameTips:'变更步骤过滤条件必须填写变更步骤和变更时间',
  aiCode1:'证件边框不完整,请重新上传',
  aiCode2:'请上传原件',
  aiCode3:'无法时别文件，请重新上传',
  aiCode4:'证件不能修改,请重新上传',
  aiCode5:'证件有反光,请重新上传',
  aiCode6:'证件模糊,请重新上传',
  aiCode7:'告警能力未开通',
  // 文件下载
  studentInformation: '学生资料',

  courseApplyStatus: '申请状态',
  courseApplyStatus0: '新建',
  courseApplyStatus1: '已提交',
  courseApplyStatus2: '补件',
  courseApplyStatus3: '加申',
  courseApplyStatus4: '顾问已提交',
  // 学生资料管理
  student: '学生',
  fkAreaCountryName: '国家/地区',
  schoolName: '学校名称',
  courseName: '课程名称',
  lastBmsApplyFollowStatusName: '课程申请最新状态',
  submitInfoStatus: '资料提交状态',
  editApplyStatus: '修改申请状态',
  completed: '已完成',
  unCompleted: '未完成',
  current: '当前',
  notStart: '未启动',
  currentHighestApplicationStatus:'当前最高申请状态：',
  // 学生申请汇总
  studentName: '学生姓名',
  studentNameZh: '学生姓名(中)',
  studentNameEn: '学生姓名(英)',
  applySchoolName: '申请学校',
  applyCourseName: '申请课程',
  courseType: '课程类型',
  bmsApplyFollowStatusName: '申请步骤状态',
  applyTotalCount: '申请资料递交总数',
  openingTime: '入学时间（月）',
  notISSUEStudent: '非ISSUE创建学生',
  checkIssueStudentInfo: '查看issue学生信息',
  bindOnlineForm: '绑定在线表单',
  bindSuccess: '绑定成功',
  bindErr: '绑定失败请检查数据',
  inputOnlineForm: '请输入在线表单编号',
  inputOnlineFormErr: '请检查输入格式',
  noSelectTips: '选择不到可进行手写输入 No matching selection, please input the name',
  batchTransferStudentManagementAccounts:'批量转学生管理帐号',
  createAccount:'创建账号',
  currentManagementAccount:'当前管理账号',
  transferManagementAccount:'转移管理账号',
  transferTips:'学生转移后，当前账号将看不到转移的学生。除非当前账号是转移账号的父级账号。',
  noTransferData:'暂无下属帐号可以选择',
  transferSuccess:'转移成功',
  transferFail:'转移失败',
}
