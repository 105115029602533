export default {
  requiredField: '必填項',
  emailValid: '請輸入正確的郵箱地址',
  birthdayValid: '請檢查出生日期',
  checkRgstAddress: '超過45字數限制, 建議避免填上整個地址, 以及請把部分內容填在備選聯繫地址',
  phoneRegionValid: '電話號碼格式不正確，請重新輸入！',
  contactPostcodeValid: '國內郵政編碼應該為5-6位！',
  contactPostcodeTaiWanValid: '台灣郵遞區號應該是3-6位！',
  contactPostcodeUKValid: '英國郵遞區號應該是5-8位！',
  englishNameValid: '請根據要求輸入拼音形式！',
  stuPhoneNumberValid: '學生手機號不能與跟進人手機號一致',
  stuEmailValid: '學生郵箱不能與跟進人郵箱一致',
  issueDateValid: "請檢查日期, 簽發日期{issueDate}不能在有效期{expriDate}後",
  phoneRegionValid2: '請補充區號！',
  phoneRegionValid3: '請補充手機號！',
  checkPasswordTime: "護照將要過期，請提供有效護照",
}
