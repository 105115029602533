export default {
  // 學生註冊
  nameZh: 'Chinese Name or Full Name',
  nameEN: 'English Name',
  lastName: 'Surname',
  firstName: 'First Name',
  gender: 'Gender',
  female: 'Female',
  male: 'Male',
  title: 'Title',
  birthday: 'Birthday',
  phoneNumber: 'Student Contact Number',
  phoneRegion: 'Area Code ',
  email: 'Email',
  emailPassword: 'Email Password',
  country: 'Country or Region of Residence',
  state: 'Province',
  city: 'City',
  region: 'District',
  postcode: 'Postal Code',
  address: 'Contact Address',
  alternativeAddr: 'Alternative Contact Address',
  fillApply: 'Save and Fill in Application Form',
  returnStuList: 'Return to the list',
  fkAreaRegion: 'Region',
  saveTemporal: "Information to be completed, temporary ",
  moreEmailTips:' (Please separate multiple email addresses with (;+space))',
  viewStudentsAddApplications:'View students and add applications',
  duplicateStudentTips:'Detected, there are {num} duplicate students!',
  viewStudentsEditThem:'View students and edit them',
  shareLink:'share link',
  notStudentEmail:'(Not Student Email)',
  submitTips:'Student information has been submitted. If you want to make modifications, please notify the consultant or make the changes in case of additional application.',

  // 學生資料管理
  isLivedSinceBirth: 'Have you lived in this country or region since birth?',
  fkStudentAppCountryIdLive: 'Current country/region',
  migrationTime: 'Migration Time',
  stayingRightTime: 'Take the right of abode time',
  isHavePassport: 'Do you have a passport',
  fkStudentAppCountryIdPassport: 'Issuing Country or Region',
  passportIssueDate: 'Issue Date',
  passportIssueExpried: 'Valid until',
  passportNumber: 'Passport Number',
  followUpPhoneCode: 'Area Code',
  followUpPhoneNumber1: "Follow up the contact's phone number 1",
  followUpPhoneCode1: 'Area Code 1',
  followUpEmail1: 'Follow Up Email 1',
  followUpPhoneNumber2: "Follow up the contact's phone number 2",
  followUpPhoneCode2: 'Area Code 2',
  followUpEmail2: 'Follow Up Email 2',
  followUpPhoneNumber3: "Follow up the contact's phone number 3",
  followUpPhoneCode3: 'Area Code 3',
  followUpEmail3: 'Follow Up Email 3',
  passportInfo: 'Passport Information',
  contactPerson: 'Contact Person in Charge',
  contactNotice: '(It is suggested to provide the mobile phone number of the consultant teacher so that the system can push students\' case progress)',
  atLeastOne: '(Fill in at least one)',
  fkStudentAppCountryId: 'Student nationality / region',
  fkStudentAppAncestryId: 'Student Lineage',
  fkStudentAppMotherLanguageId: 'Student\'s native language',
  fkStudentAppCountryIdBirth: 'Country or Region of Birth',
  cityBirth: 'Birth City',

  regStartTime: 'Start time of filing',
  regEndTime: 'End time of filing',
  export: 'Export',
  applicationStatus: 'Application current processing status',
  failureReason: 'Failure Reason',
  regGmtCreate: 'Filing time',
  edit: 'Editor application',
  addApplyCourse: 'Add course',
  personInfo: 'Personal Information',
  applyFollow: 'Application Follow-up status',
  allDataRegiest: 'Registration of Complete Information',
  baseDataRegiest: 'Basic Registration Information',
  intelligentIdentificationStudentInfo: 'intelligent identification information',
  intelligentIdentificationTips: 'Through OCR & NLP recognition technology, key information in images and documents can be extracted to simplify data entry',
  uploadPassportNational: 'Upload passport pictures from other regions',
  uploadPassport: 'Upload Mainland China passport picture',
  uploadBackground: 'Upload background information',
  continuetofill: 'Continue to fill',
  stuName: 'Name(Chinese)',
  stuNameEn: 'Name(English)',
  stuGender: 'gender',
  stuNation: 'Nationality',
  stuBirth: 'Birthdate',
  stuAddr: 'Address',
  stuIdcard: 'Identity Card',
  stuBirthPlace: 'Birthplace',
  stuPassport: 'Passport',
  stuEduExp: 'Educational experience',
  stuWorkExp: 'Work experience',
  notUpload: 'not uploaded ',
  stepStatusChangeStartTime: 'Step Status Change Start Time',
  stepStatusChangeEndTime: 'Step Status Change End Time',
  fkItemChangeStepName: 'Change of Application Status',
  fkItemChangeStepNameTips:'The change step filter condition must fill in the change step and change time',
  aiCode1:'The border of the certificate is incomplete, please upload again',
  aiCode2:'Please upload the original',
  aiCode3:'The file cannot be recognized, please upload again',
  aiCode4:'The certificate cannot be modified, please upload again',
  aiCode5:'The certificate is reflective, please upload again',
  aiCode6:'The certificate is blurred, please upload again',
  aiCode7:'The alarm capability is not enabled',
  // 文件下載
  studentInformation: 'Student Information',

  courseApplyStatus: 'Application Status',
  courseApplyStatus0: 'New',
  courseApplyStatus1: 'Submitted',
  courseApplyStatus2: 'Request For Evidence',
  courseApplyStatus3: 'Add',
  courseApplyStatus4: 'Consultant Submitted',
  // 學生資料管理
  student: 'Student',
  fkAreaCountryName: 'Country/Region',
  schoolName: 'School Name',
  courseName: 'Course Name',
  lastBmsApplyFollowStatusName: 'Current Status of Course Application',
  submitInfoStatus: 'Submission Status',
  editApplyStatus: 'Modifying Application Status',
  completed: 'Completed',
  unCompleted: 'unCompleted',
  current: 'Current',
  notStart: 'Not Start',
  currentHighestApplicationStatus:'Current highest application status:',

  // 學生申請匯總
  studentName: 'Student Name',
  studentNameZh: 'Student Name(Chinese)',
  studentNameEn: 'Student Name(English)',
  applySchoolName: 'School of Application',
  applyCourseName: 'Proposed Program',
  courseType: 'Course Type',
  bmsApplyFollowStatusName: 'Application Step Status',
  applyTotalCount: 'Total number of applications submitted',
  openingTime: 'Enrollment Time (month)',
  notISSUEStudent: 'Non-Issue Create Student',
  checkIssueStudentInfo: 'View Student Information of Old Issue',

  bindOnlineForm: 'Bind online form',
  bindSuccess: 'Binding succeeded',
  bindErr: 'Binding failed and please check data',
  inputOnlineForm: 'Please input online form id',
  inputOnlineFormErr: 'Please check input format',
  noSelectTips: 'No matching selection, please input the name',
  batchTransferStudentManagementAccounts:'Batch transfer of student management accounts',
  createAccount:'Create an account',
  currentManagementAccount:'Current management account',
  transferManagementAccount:'Transfer management account',
  transferTips:'After a student is transferred, the transferred student will not be visible to the current account. Unless the current account is the parent account of the transfer account.',
  noTransferData:'There are currently no subordinate accounts to choose from.',
};
